import React from "react";
import { convertToSlug } from "../utils/common";

export default function TableContents({ data }) {
  const handleClick = (e) => {
    e.preventDefault();
    const sectionid = e.target.getAttribute("href")?.split("#")[1];

    const section = document.getElementById(`${sectionid}`);
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  return (
    <div className="lg:pt-[100px] lg:w-[300px] lg:pb-[120px] content-table hidden lg:block">
      <div className="h-full">
        <div className="sticky top-[67px] p-2">
          <h1 className="text-lg capitalize font-poppin font-bold text-[#AF872D]">
            {data?.title}
          </h1>
          <ul className="ml-3 mt-3 list-none">
            {data?.items?.map((item, index) => {
              return (
                <a
                  href={`#${convertToSlug(item)}`}
                  className="text-gray-400 text-sm font-roboto"
                  onClick={handleClick}
                  key={index}
                >
                  {item}
                </a>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
