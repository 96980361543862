import React, { useEffect } from "react";

export default function ScrollProgress() {
  const onScroll = () => {
    const winScroll = document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = (winScroll / height) * 100;
    document.getElementById("progressBar").style.width = scrolled + "%";
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <div
      id="progressBar"
      className="block lg:hidden fixed top-0 left-0 h-[5px] z-50 bg-[#AF872D]"
    />
  );
}
