import React from "react";
import { PortableText, toPlainText } from "@portabletext/react";
import { getImageDimensions } from "@sanity/asset-utils";
import imageUrlBuilder from "@sanity/image-url";
import { convertToSlug } from "./../utils/common";
import NavLink from "./NavLink";
import { SvgBar } from "./svg";

const builder = imageUrlBuilder({
  projectId: "p5dzjrg7",
  dataset: "production",
});

const ArticleImage = ({ value, width, height, url, borderless }) => {
  return (
    <img
      className={borderless ? "" : "shadow-md"}
      src={builder.image(value?.asset?._ref)}
      alt={value?.alt ? value?.alt : url}
      width={width}
      height={height}
      style={{
        marginBottom:
          value.attribution && value.attribution.length > 0 ? 0 : 15,
        marginTop: 15,
      }}
    />
  );
};

// const tableContent = (content) => {
//   console.log("---content", content);
//   switch (content?._type) {
//     case "tableBlockContent":
//       return (
//         <PortableText
//           value={content?.blockContent}
//           components={{
//             marks: {
//               color: (item) => (
//                 <span style={{ color: item?.value?.hex }}>
//                   {item?.children}
//                 </span>
//               ),
//             },
//           }}
//         />
//       );
//     case "commonLink":
//       return (
//         <NavLink
//           type={content?.type}
//           label={content?.label}
//           path={content?.path}
//           url={content?.url}
//           className="border px-7 py-2 border-[#AF872D] bg-[#AF872D] transition-colors duration-500 hover:bg-white hover:text-[#AF872D] text-white font-roboto font-semibold hover:no-underline"
//         />
//       );
//     case "image":
//       const { width, height } = getImageDimensions(content?.asset);
//       return (
//         <img
//           src={builder.image(content?.asset?._ref)}
//           alt=""
//           width={width}
//           height={height}
//         />
//       );
//   }
// };

export default function PortableContent({ article }) {
  const url =
    typeof window !== "undefined"
      ? window.location.pathname.replace("/", "")
      : "";

  return (
    <PortableText
      value={article._rawContent}
      components={{
        marks: {
          color: (item) => (
            <span style={{ color: item?.value?.hex }}>{item?.children}</span>
          ),
          link: ({ value, children }) => {
            const target = (value?.href || "").startsWith("http")
              ? "_blank"
              : undefined;
            return (
              <a
                href={value?.href}
                target={target}
                rel={target === "_blank" && "noindex nofollow"}
              >
                {children}
              </a>
            );
          },
        },
        block: {
          h2: ({ children }) => (
            <h2 id={convertToSlug(children)}>{children}</h2>
          ),
          h3: ({ children }) => (
            <h3 id={convertToSlug(children)}>{children}</h3>
          ),
          h4: ({ children }) => (
            <h4 id={convertToSlug(children)}>{children}</h4>
          ),
          h5: ({ children }) => (
            <h5 id={convertToSlug(children)}>{children}</h5>
          ),
          h6: ({ children }) => (
            <h6 id={convertToSlug(children)}>{children}</h6>
          ),
        },
        types: {
          articleImage: ({ value }) => {
            const { width, height } = getImageDimensions(value);
            return (
              <>
                <div
                  className={`flex justify-center ${
                    value?.borderless ? "" : "blog-img"
                  }`}
                >
                  {value.anchorLink ? (
                    <a
                      href={value.anchorLink}
                      target={"_blank"}
                      rel="noreferrer noopener"
                    >
                      <ArticleImage
                        value={value}
                        height={height}
                        width={width}
                        url={url}
                        borderless={value?.borderless}
                      />
                    </a>
                  ) : (
                    <ArticleImage
                      value={value}
                      height={height}
                      width={width}
                      url={url}
                      borderless={value?.borderless}
                    />
                  )}
                </div>
                {value.attribution && value.attribution.length > 0 && (
                  <div className="mt-2 mb-4 text-sm leading-6 text-[#676a6b] text-center">
                    <p className={"text-center my-0"}>
                      {toPlainText(value.attribution)}
                    </p>
                  </div>
                )}
              </>
            );
          },
          tableContent: ({ value }) => (
            <div className="border-[1px] border-[#e1e2e6] rounded-[2px] p-9 my-8">
              <h2>{value?.title}</h2>
              {value?.items && value?.items?.length > 0 && (
                <ul className="pl-0 mt-5 ml-0 list-none">
                  {value?.items?.map((item, index) => (
                    <li
                      className={'before:content-["📌"] before:pr-4 font-ssp'}
                      key={index}
                    >
                      <a
                        href={"#" + convertToSlug(item.trim())}
                        className={"text-black underline decoration-[#AF872D]"}
                      >
                        {item}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ),
          embedCode: ({ value }) => (
            <div className="pt-1">
              <div
                className="flex justify-center"
                dangerouslySetInnerHTML={{ __html: value?.code }}
              ></div>
              {value.attribution && value.attribution.length > 0 && (
                <div className="my-4 text-sm leading-6 text-[#676a6b] text-center">
                  <PortableText value={value.attribution} />
                </div>
              )}
            </div>
          ),
          video: ({ value }) => (
            <div className="py-2">
              <div
                className="video-container"
                dangerouslySetInnerHTML={{ __html: value?.embededCode }}
              ></div>
              {value.attribution && value.attribution.length > 0 && (
                <div className="my-4 text-sm leading-6 text-[#676a6b] text-center">
                  <PortableText value={value.attribution} />
                </div>
              )}
            </div>
          ),
          keyTakeways: ({ value }) => (
            <div>
              <div className="relative key_points">
                <h3 className="px-5 pt-6 pb-2 text-base tracking-wider uppercase">
                  Key Takeaways
                </h3>
                <div className="px-5 pb-5">
                  <ul>
                    {value?.items.map((item) => (
                      <li className="block_li">{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ),
          callout: ({ value }) => (
            <div>
              {value?.message && (
                <div className="border-[1px] border-[#e1e2e6] rounded-[2px] p-2 my-8">
                  <div className="relative pl-8 pr-7 before:content-['⚡'] before:inline-block before:absolute before:left-2">
                    <p className={"inline"}>
                      <span className={"pr-2 font-bold"}>
                        {value?.prefix || "Important"}:
                      </span>
                      {value?.message}
                    </p>
                  </div>
                </div>
              )}
            </div>
          ),
          callout2: ({ value }) => {
            if (value) {
              return (
                <div className="w-full overflow-hidden bg-white px-[30px] py-5 border border-solid border-[#AF872D] rounded-xl">
                  <div className="py-5 flex flex-col  md:flex-row md:space-x-10">
                    <div className="w-full">
                      <img
                        src={builder.image(value?.thumbnail?.asset?._ref)}
                        alt="thumbanil"
                        width={320}
                        height={320}
                        className="my-0 md:my-2 mx-auto"
                      />
                    </div>
                    <div>
                      <h5 className="!font-medium text-xl !font-lora">
                        {value?.heading}
                      </h5>
                      {value?.description && (
                        <div className="text-sm content">
                          <PortableText
                            value={value?.description}
                            components={{}}
                          />
                        </div>
                      )}
                      <a
                        href={value?.actionButton?.url || "!#"}
                        className="hover:no-underline  text-black hover:text-[#AF872D] transition-colors duration-200 ease-linear space-x-2 rounded-lg mt-1 uppercase text-sm font-roboto font-bold flex items-center"
                      >
                        <span>{value?.actionButton?.text} </span>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              );
            }
          },
          tableBuilder: ({ value }) => {
            if (value) {
              return (
                <div className="py-3 table-builder overflow-y-auto">
                  <h3>{value?.title}</h3>
                  <div className="mt-5">
                    <table className="table-fixed border-collapse border border-slate-400">
                      {value?.rows?.length > 0 &&
                        value?.rows?.map((row) => (
                          <tr key={row?._key}>
                            {row?.col?.map((col, i) => (
                              <td
                                style={{ backgroundColor: col?.colBg?.hex }}
                                className={`border border-slate-300 px-2 w-[150px] sm:w-full`}
                                key={i}
                              >
                                {col?.content?.blockContent && (
                                  <PortableText
                                    value={col?.content?.blockContent}
                                    components={{
                                      marks: {
                                        color: (item) => (
                                          <span
                                            style={{ color: item?.value?.hex }}
                                          >
                                            {item?.children}
                                          </span>
                                        ),
                                        link: ({ value, children }) => {
                                          const target = (
                                            value?.href || ""
                                          ).startsWith("http")
                                            ? "_blank"
                                            : undefined;
                                          return (
                                            <a
                                              href={value?.href}
                                              target={target}
                                              rel={
                                                target === "_blank" &&
                                                "noindex nofollow"
                                              }
                                            >
                                              {children}
                                            </a>
                                          );
                                        },
                                      },
                                      types: {
                                        image: ({ value }) => {
                                          return (
                                            <img
                                              src={builder.image(
                                                value?.asset?._ref
                                              )}
                                              alt=""
                                              width={120}
                                              height={80}
                                            />
                                          );
                                        },
                                        commonLink: ({ value }) => {
                                          return (
                                            <NavLink
                                              type={value?.type}
                                              label={value?.label}
                                              path={value?.path}
                                              url={value?.url}
                                              className="border text-center block mb-4 py-2 border-[#AF872D] bg-[#AF872D] transition-colors duration-500 hover:bg-white hover:text-[#AF872D] text-white font-roboto font-semibold hover:no-underline"
                                            />
                                          );
                                        },
                                      },
                                    }}
                                  />
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                    </table>
                  </div>
                </div>
              );
            }
          },
        },
        list: {
          bullet: ({ children }) => (
            <ul className="relative list-none">{children}</ul>
          ),
        },
        listItem: {
          bullet: (item) => (
            <li className="relative mx-0 my-1 before:content-[''] before:absolute before:top-[6px] before:-left-[14px] before:h-[6px] before:w-[6px] before:rounded-full before:bg-black before:inline-block">
              {item?.children}
            </li>
          ),
        },
      }}
    />
  );
}
