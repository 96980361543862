import React from "react";
import { Link } from "gatsby";

export default function NavLink({
  type,
  path,
  url,
  label,
  className = "",
  onClick,
}) {
  if (type === "internal")
    return (
      <Link to={path} className={className} onClick={onClick}>
        {label}
      </Link>
    );
  else
    return (
      <a
        href={url}
        className={className}
        target="_blank"
        rel="noreferrer noopener nofollow"
        onClick={onClick}
      >
        {label}
      </a>
    );
}
