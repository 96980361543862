import {graphql, useStaticQuery} from "gatsby"

export const useSite = () => {
	const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
					keywords
                    twitterUsername
                    image
                    siteUrl
                }
            }
            sanitySite {
                title
                siteMetadata {
                    title
                    schema
                    keywords
                    image {
                        asset {
                            gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                        }
                    }
                }
                aboutNick {
                    image {
                        asset {
                            gatsbyImageData(formats: WEBP, placeholder: BLURRED, width: 150)
                        }
                    }
                    bio
                }
                socialMediaLinks {
                    linkedIn
                    instagram
                    facebook
                }
            }
        }
	`)

	return data.sanitySite || data.site
}