import React, { Fragment } from "react";
import Layout from "../components/Layout";
import ArticleA from "../components/ArticleA";
import { graphql, Script } from "gatsby";
import { SEO } from "../components/SEO";
import Footer from "../components/Footer";

const Article = ({ data: { sanityArticle }, location }) => {
  return (
    <Fragment>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            var disqus_config = function () {
              this.page.url = 'https://nickwolny.com/${location?.pathname}';
              this.page.identifier = '${sanityArticle?.id}'; 
            };
            (function() {
            var d = document, s = d.createElement('script');
            s.src = 'https://nick-wolny.disqus.com/embed.js';
            s.setAttribute('data-timestamp', +new Date());
            (d.head || d.body).appendChild(s);
            })();
          `,
        }}
      />
      <Layout>
        <ArticleA article={sanityArticle} />
      </Layout>
      <Footer />
    </Fragment>
  );
};

export default Article;
export const Head = ({ data: { sanityArticle } }) => {
  return <SEO metadata={sanityArticle?.pageMetadata || {}} />;
};
export const query = graphql`
  query ($categoryId: String!, $articleId: String!) {
    sanityArticle(
      category: { elemMatch: { id: { eq: $categoryId } } }
      id: { eq: $articleId }
    ) {
      featuredImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
      _rawFeaturedImageattribution
      templateType
      slug {
        current
      }
      pageMetadata {
        schema
        description
        keywords
        title
        canonical
        image {
          asset {
            url
          }
        }
      }
      heading
      id
      category {
        slug {
          current
        }
        title
        id
        description
      }
      author {
        name
        id
        email
        bio
      }
      _rawContent
      tableContent {
        title
        items
      }
      _createdAt(formatString: "MMMM DD YYYY")
      _updatedAt(formatString: "MMMM DD YYYY")
      readTime
      summary
      sidebarImages {
        ... on SanityArticleImage {
          _type
          alt
          anchorLink
          title
          asset {
            gatsbyImageData
          }
        }
        ... on SanityRelatedLinks {
          links {
            type
            label
            path
            url
          }
          _type
        }
        ... on SanityCodeBlock {
          _type
          code
        }
      }
    }
  }
`;
